/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 14번째 로그에서는 법사님(@asbubam)을 모시고 VPC, 블로깅, patreon에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://naver.me/x0wJs4BQ"
  }, "우아한 형제들 작은집 - 네이버 지도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.patreon.com/hc/en-us/articles/204606125-How-do-you-calculate-fees-"
  }, "How does Patreon calculate fees for creators? – Patreon Help Center")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.2dal.com/2017/09/12/aws-vpc-basic/"
  }, "AWS VPC basic | asbubam's blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=109486799"
  }, "그림으로 배우는 클라우드 인프라와 API - 신상재 번역")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/homelab/"
  }, "homelab - reddit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/general/latest/gr/aws_service_limits.html#limits_elastic_load_balancer"
  }, "AWS Service Limits - Elastic Load Balancing Limits")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-paramstore.html"
  }, "AWS Systems Manager Parameter Store - AWS Systems Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/AmazonECS/latest/developerguide/specifying-sensitive-data.html"
  }, "Specifying Sensitive Data - Amazon Elastic Container Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/transit-gateway/"
  }, "AWS Transit Gateway - Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.2dal.com/"
  }, "asbubam's blog | Just enjoy the show.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/asbubam/status/973828322789675008?s=20"
  }, "asbubam on Twitter: \"AWS 개인 계정에 Kubernetes 클러스터를 올리고 삽질하는...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.2dal.com/2018/12/31/nat-gateway-to-nat-instance/"
  }, "AWS NAT Gateway에서 NAT instance로 전환하기 | asbubam's blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/SeungWooLee2/20130416"
  }, "개발자 이승우 이력서 (2016) - 슬라이드 셰어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://t.co/DxXKMjrVRT"
  }, "발표의 정석 by 하용호")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.insightbook.co.kr/book/programming-insight/%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%98%EB%B0%8D-%EB%A3%A8%EB%B9%84-%EA%B0%9C%EC%A0%95%ED%8C%90/"
  }, "프로그래밍 루비 개정판 – 도서출판 인사이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/"
  }, "인프런 - 배움의 가치를 높이세요")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://44bits.tv"
  }, "개발자 유튜브 채널 - 44bits.tv")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://msdn.microsoft.com/"
  }, "MSDN - Microsoft Developer Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCLLncfeIYljE0o_yUw7MkcA"
  }, "코딩의 신 아샬 - 유튜브")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colah.github.io/"
  }, "Home - colah's blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://distill.pub/"
  }, "Distill — Latest articles about machine learning")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
